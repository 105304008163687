function clickTableRow($el, dialog, parent) {
    

    $el.find('.datagrid_div .tableRow td').click(function (e) {
        if (!$(e.target).is('a') && !$(e.target).is('input') && !$(this).is('.td_action') && !$(this).closest('table').hasClass('no-clickable-rows')) {
            var $row = $(this).parent();
            $row.find('.td_action a:first').click();
        }
        if($(this).is('.td_action') && $(e.target).is($(this))) {
            
            var $row = $(this).parent();
            $row.find('.td_action a:first').click();
        }

    });
    $el.find('.datagrid_div .td_action a').click(function (e) {
        e.preventDefault();
        switch ($(this).attr('target')) {
            case 'modal' :
//                var url = $(this).attr('href');
//                createModal(dialog, url, $(this).attr('title'));
                break;
            case '_blank' :
                window.open($(this).attr('href'));
                break;
            default :
                document.location.href = $(this).attr('href');
                break;
        }
    });

    $el.find('a[target="modal"]').click(function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        createModal(dialog, url, $(this).attr('title'));
    });

    $el.find('table.datagrid_checkbox_click .tableRow').click(function (e) {
        if ($(e.target).is('input')) {
            return true;
        }

        if ($(this).find('input[type="checkbox"]').is(':checked')) {
            $(this).find('input[type="checkbox"]').prop('checked', false);
        } else {
            $(this).find('input[type="checkbox"]').prop('checked', true);
        }
//        $(this).find('input[type="checkbox"]').click();
    });


}


content.addFunction(function ($el, dialog, parent) {
    var to = null;
    $el.find('.datagrid_div thead.filter input').keyup(function () {
        if (to) {
            clearTimeout(to);
        }
        var $input = $(this);
        to = setTimeout(function () {
            var request = loader.show();
//            var $loader = $('<div class="loading"><div class="shade"></div><div class="center-block"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></div></div>');
//            $loader.find('.shade').height($el.find('tbody').height());
//            $el.find('tbody').before($loader);
            $.post(dialog.url,
                    $input.attr('name') + '=' + escape($input.val()), function (data) {
                var $content = $(data.content);
                $el.find('tbody').html($content.find('tbody').html());
                $el.find('.pagination').html($content.find('.pagination').html());
                clickTableRow($el, dialog, parent);
                loader.hide(request);
//                $loader.remove();
            }, 'json');
        }, 500);
    });

});

content.addFunction(function ($el, dialog, parent) {
    $el.find('.datagrid_div thead.filter select').change(function () {
        var request = loader.show();
        var $input = $(this);
        $.post(dialog.url,
                $input.attr('name') + '=' + escape($input.val()), function (data) {
            $el.find('tbody').html($(data.content).find('tbody').html());
            clickTableRow($el, dialog, parent);
            loader.hide(request);
            
        }, 'json');
    });
});

content.addFunction(function ($el, dialog, parent) {
    var to = null;
    $el.find('.topfilter input').keyup(function () {
        if (to) {
            clearTimeout(to);
        }
        var $input = $(this);
        to = setTimeout(function () {
            var request = loader.show();
//            var $loader = $('<div class="loading"><div class="shade"></div><div class="center-block"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></div></div>');
//            $loader.find('.shade').height($el.find('tbody').height());
//            $el.find('tbody').before($loader);
            $.post(dialog.url,
                    $input.attr('name') + '=' + escape($input.val()), function (data) {
                var $content = $(data.content);
                $el.find('tbody').html($content.find('tbody').html());
                $el.find('.pagination').html($content.find('.pagination').html());
                clickTableRow($el, dialog, parent);
//                $loader.remove();
                loader.hide(request);
                
            }, 'json');
        }, 500);
    });

});

content.addFunction(function ($el, dialog, parent) {
    $el.find('.topfilter select').change(function () {
        var request = loader.show();
        var $input = $(this);
        $.post(dialog.url,
                $input.attr('name') + '=' + escape($input.val()), function (data) {
            $el.find('tbody').html($(data.content).find('tbody').html());
            clickTableRow($el, dialog, parent);
            loader.hide(request);


        }, 'json');
    });

});



content.addFunction(function ($el, dialog, parent) {

    clickTableRow($el, dialog, parent);
});

content.addFunction(function ($el, dialog, parent) {

    $('.dragsort').sortable({
        stop: function (event, ui) {
            var values = {
                reorder: true,
                elements: []
            }
            $(this).find('tr[data-drop_id]').each(function () {
                values.elements[values.elements.length] = $(this).data('drop_id');
            })
            $.post($(this).parent().data('drop_uri'), values, function (data) {
//                d.dialog('option', 'disabled', false);
                if (!$el.find('div.messages').html()) {
                    $el.prepend('<div class="messages" />');
                }
                $el.find('div.messages').html(data.content);
//                fncSetupMessages();

            }, 'json')
        }

    });
});
content.addFunction(function ($el, dialog, parent) {
    $el.find('.toggle_search_on').hide();
    $el.find('.toggle_search_off').click(function () {
        $el.find('thead.filter').hide();
        $el.find('.toggle_search_on').show();
        $(this).hide();
    });
    $el.find('.toggle_search_on').click(function () {
        $el.find('thead.filter').show();
        $el.find('.toggle_search_off').show();
        $(this).hide();
    });
});

content.addFunction(function ($el, dialog, parent) {


    $el.find('th.sortable .asc').click(function () {
        $el.find('th.sort-asc').removeClass('sort-asc');
        $el.find('th.sort-desc').removeClass('sort-desc');
        $(this).parent().addClass('sort-asc');
        var data = {
            "sortby": $(this).parent().attr('value'),
            "sorton": "asc"
        };
        $.post(dialog.url, data, function (data) {
            var $content = $(data.content);
            $el.find('tbody').html($content.find('tbody').html());
        }, 'json');
    });
    $el.find('th.sortable .desc').click(function () {
        $el.find('th.sort-asc').removeClass('sort-asc');
        $el.find('th.sort-desc').removeClass('sort-desc');
        $(this).parent().addClass('sort-desc');
        var data = {
            "sortby": $(this).parent().attr('value'),
            "sorton": "desc"
        };
        $.post(dialog.url, data, function (data) {
            var $content = $(data.content);
            $el.find('tbody').html($content.find('tbody').html());
        }, 'json');
    });

    $el.find('th.sortable span.label-sort').click(function () {
        if ($(this).parent().hasClass('sort-asc')) {
            $(this).parent().find('.desc').click();
        } else {
            $(this).parent().find('.asc').click();
        }
    });
});



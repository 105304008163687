$(document).ready(function () {
    
    $('.albums-button-modal-label').click(function () {
//        $(this).closest('.albums-image-decorator-row').find('.modal-label .modal-body').html('html'); 
        var request = loader.show();
        var $el = $(this);
        var elements = document.body.getElementsByClassName('albums-image-decorator-left');
        var images = {};

        for (var i = 0; i < elements.length; i++) {
            if ($(elements[i]).is('img')) {
                images[i] = $(elements[i]).attr('data-imageid');
            }
        }

        var postdata = {
            'images': images,
        }

        $.post('/albums/image/get-original-crops-html', postdata, function (data, status) {
            var html = '<div class="row">';
            html += data;
            html += '</div>';
            var $modal = $($el.attr('data-target'));
            $modal.find('.modal-body').html(html);
//            $el.closest('.albums-image-decorator-row').find('.modal-label .modal-body').html(html);
            loader.hide(request);
            uploadImageFromLibrary();
        });
    });

    $('.albums-button-modal-library').click(function () {
        var request = loader.show();

//        var $modal = $(this).closest('.albums-image-decorator-row').find('.modal-library');
        var $modal = $($(this).attr('data-target'));

        var searchTimeOut = null;
        var fncSearch = function () {

            if (searchTimeOut) {
                clearTimeout(searchTimeOut);
            }
            searchTimeOut = setTimeout(function () {
                var request = loader.show();
                var postdata = {
                    'name-filter': $modal.find('#albums-name-filter').val(),
                    'categorie-filter': $modal.find('#albums-categorie-filter').val(),
                };

                $.post("/albums/image/get-images-html", postdata, function (data, status) {
                    var html = data;

                    $modal.find('.albums-images-row').html(html);
                    uploadImageFromLibrary();
                    loader.hide(request);
                });
            }, 500);
        };

        $modal.find('select.albums-filter').off('change').on('change', fncSearch);
        $modal.find('input.albums-filter').off('keyup').on('keyup', fncSearch);


        var postdata = {
            'name-filter': $modal.find('#albums-name-filter').val(),
            'categorie-filter': $modal.find('#albums-categorie-filter').val(),
        };

        $.post("/albums/image/get-images-html", postdata, function (data, status) {
            var html = data;

            $modal.find('.albums-images-row').html(html);
            uploadImageFromLibrary();
            loader.hide(request);
        });
    });


//    uploadImageFromLibrary();
    toggleOverlay();
    albumDeleteModuleImages();
    resizeImage();

});

function toggleOverlay() {

    $('select.albums-categorie').change(function () {
        if ($(this).val() < 0) {
            $(this).closest('.modal-body').find('.albums-drag-overlay').show();
        } else {
            $(this).closest('.modal-body').find('.albums-drag-overlay').hide();
            uploadImageFromDrag($(this).val());
        }
    });
}

function albumDeleteModuleImages() {
    $('.albums-delete-module-image').click(function () {
        var html = '<div class="albums-image-decorator-left no-image" style="height:150px;width:l00%;border:1px solid #ccc;padding: 8px;">'
                + 'Nog geen afbeelding toegevoegd.'
                + '</div></div>';

        $(this).closest('.albums-image-decorator-row').find('input.sys-form-element-image-upload-display-hidden').val(null);

        $(this).closest('.albums-image-decorator-row').find('.albums-image-decorator-left').replaceWith(html);

        $(this).remove();

    });
}


function resizeImage() {
    $('img.albums-image-decorator-left').off('click').on('click' ,function () {

        var request = loader.show();
        $el = $(this);
        var $modal = $($(this).closest('.albums-image-decorator-row').find('.albums-button-modal-upload').attr('data-target'));


//        var cropper_x = $(this).closest('.albums-image-decorator-row').find('.albums-cropper-info').attr('data-x');
//        var cropper_y = $(this).closest('.albums-image-decorator-row').find('.albums-cropper-info').attr('data-y');
        var cropper_x = $('#' + $modal.attr('data-element-id') + '-element').find('.albums-cropper-info').attr('data-x');
        var cropper_y = $('#' + $modal.attr('data-element-id') + '-element').find('.albums-cropper-info').attr('data-y');
        
//        console.log(cropper_x);
//        console.log(cropper_y);
        
        var image_id = $(this).attr('data-imageid');

        var postdata = {
            'image_id': image_id
        };

        $.post('/albums/image/get-original-crop', postdata, function (data, status) {
            loader.hide(request);
            var crop = data;
            var src = crop['image_location'];

            var $cropper = $('<div id="image-cropper" />');
            var html =
                    '<div class="cropit-preview"></div>' +
                    '<input type="range" class="cropit-image-zoom-input">'
                    ;

            var htmlbutton = '<span class="export btn btn-primary albums-save-crop-button">Nieuwe uitsnede opslaan</span>'
                    + '<span class="export-update btn btn-primary albums-save-crop-button">Uitsnede bewerken</span>';
            $modal.find('.modal-footer').prepend(htmlbutton);
            console.log('appended buttons');
            $cropper.append(html);

            //        var orightml = $(this).closest('.albums-image-decorator-row').find('.modal-label .modal-body').html();
            var orightml = $modal.find('.modal-body').html();


            $modal
                    .off('hidden.bs.modal')
                    .on('hidden.bs.modal', function (e) {
                        $(this).find('.modal-body').html(orightml);
                        $(this).find('.albums-save-crop-button').remove();
            console.log('removed buttons');

                        uploadImageFromLibrary();

                    });

            $modal.find('.modal-body').html($cropper);

            
            $modal.modal('show');

            
            var modal_width = 858;
            if($(window).width() < 992) {
                modal_width = 558;
            }
            

            if (modal_width > cropper_x) {
                var cropper_width = cropper_x;
                console.log(1);
            } else { 
                var cropper_width = modal_width;
                console.log(2);
            }

            console.log(cropper_width);


//            var cropper_width = 858;

            var cropper_settings = {
                exportZoom: cropper_x / cropper_width,
                imageBackground: true,
                imageBackgroundBorderWidth: 20,
                width: cropper_width,
                height: cropper_width * cropper_y / cropper_x,
                smallImage: 'stretch',
                imageState: {
                    //            src: '/temp/lorempixel.jpg',
                    src: src,
                },
                onImageLoaded: function () {

                    if ($el.attr('data-zoom')) {
                        cropper_settings.initialZoom = $el.attr('data-zoom');
                        $("#image-cropper").cropit('zoom', $el.attr('data-zoom'));
                    }
                    if ($el.attr('data-left')) {
                        cropper_settings.offset = {x: $el.attr('data-left'), y: $el.attr('data-top')};
                        $("#image-cropper").cropit('offset', {x: $el.attr('data-left'), y: $el.attr('data-top')});
                    }
                }
            }

            $("#image-cropper").cropit(cropper_settings);

            $('.export').click(function () {
                var request = loader.show();
                var offset = $("#image-cropper").cropit('offset');
                var left = offset['x'];
                var top = offset['y'];
                var zoom = $("#image-cropper").cropit('zoom');
                var imageData = $('#image-cropper').cropit('export');
                saveImage(imageData, image_id, src, $(this).closest('.modal'), left, top, zoom, request);
                //            window.open(imageData);
            });

            $('.export-update').click(function () {
                var request = loader.show();
                var offset = $("#image-cropper").cropit('offset');
                var left = offset['x'];
                var top = offset['y'];
                var zoom = $("#image-cropper").cropit('zoom');
                var imageData = $('#image-cropper').cropit('export');
                saveImage(imageData, image_id, src, $(this).closest('.modal'), left, top, zoom, request, $el.attr('data-cropid'));
                //            window.open(imageData);
            });

        }, 'json');
    });
}



function rescope_me($that, id) {

    $that.off('click').on('click', function () {
        $that.closest('.modal-body').find('.album_upload_file_click').attr('action', '/albums/categorie/upload/id/' + id);
        $that.closest('.modal-body').find('.album_upload_file_click input').click();
    });

    $that.parent().find('.album_upload_file_click input').off('change').on('change', function () {
        $(this).closest('.album_upload_file_click').submit();
        $(this).val(null);
    });
 

    var divId = $that.attr('id');
    var url = '/albums/categorie/upload/id/' + id;

    var orightml = $that.closest('.modal-body').html();

    $that.closest('.modal')
            .off('hidden.bs.modal')
            .on('hidden.bs.modal', function (e) {
                $(this).find('.modal-body').html(orightml);
                $(this).find('.albums-save-crop-button').remove();
                $(this).find('.albums-drag-overlay').show();
                toggleOverlay();

                uploadImageFromDrag();

            });

    uploadAFile(divId, url, function (json) {

//        var cropper_x = $that.closest('.albums-image-decorator-row').find('.albums-cropper-info').attr('data-x');
//        var cropper_y = $that.closest('.albums-image-decorator-row').find('.albums-cropper-info').attr('data-y');
        var cropper_x = $('#' + $that.closest('.modal').attr('data-element-id') + '-element').find('.albums-cropper-info').attr('data-x');
        var cropper_y = $('#' + $that.closest('.modal').attr('data-element-id') + '-element').find('.albums-cropper-info').attr('data-y');

        var data = JSON.parse(json);
        var image = data['images'][0];
        var src = image['image_location'];
        var image_id = image['id'];
        
        var $cropper = $('<div id="image-cropper" />');
        var html =
                '<div class="cropit-preview"></div>' +
//                        '<div class="image-size-label">' +
//                        '  Resize image' +
//                       ' </div>' +
                '<input type="range" class="cropit-image-zoom-input">'
                ;

        var htmlbutton = '<span class="export btn btn-primary albums-save-crop-button">Gebruik deze uitsnede</span>';
        $that.closest('.modal').find('.modal-footer').prepend(htmlbutton);

        $cropper.append(html);


        if (($that.closest('.modal-body').innerWidth() - 40) > cropper_x) {
            var cropper_width = cropper_x;
        } else {
            var cropper_width = $that.closest('.modal-body').innerWidth() - 40;
        }
        

        $that.closest('.modal-body').html($cropper);

        if ((parseInt(image['width']) < parseInt(cropper_x)) || (parseInt(image['height']) < parseInt(cropper_y))) {
            var warning = '<div class="alert alert-warning" role="alert">De geselecteerde afbeelding is te klein en zal worden uitgerekt.</div>';
            $('#image-cropper').prepend(warning);
        }

        $("#image-cropper").cropit({
            exportZoom: cropper_x / cropper_width,
            imageBackground: true,
            imageBackgroundBorderWidth: 20,
            width: cropper_width,
            height: cropper_width * cropper_y / cropper_x,
            smallImage: 'stretch',
            imageState: {
                src: src,
            },
        });

        $('.export').click(function () {
            var request = loader.show();
            var offset = $("#image-cropper").cropit('offset');
            var left = offset['x'];
            var top = offset['y'];
            var zoom = $("#image-cropper").cropit('zoom');
            var imageData = $('#image-cropper').cropit('export');
            saveImage(imageData, image_id, src, $(this).closest('.modal'), left, top, zoom, request);
//                    window.open(imageData);
        });

    }, function () {}
    );


//    $('#resultFrame').load(function () {
    $('#' + $that.closest('.modal').attr('data-element-id') + '_resultFrame').load(function () {
        var json = $(this).contents().find('body pre').html();
        var data = JSON.parse(json);
        var image = data['images'][0];

        var cropper_x = $('#' + $that.closest('.modal').attr('data-element-id') + '-element').find('.albums-cropper-info').attr('data-x');
        var cropper_y = $('#' + $that.closest('.modal').attr('data-element-id') + '-element').find('.albums-cropper-info').attr('data-y');

        var src = image['image_location'];
        var image_id = image['id'];


        var $cropper = $('<div id="image-cropper" />');
        var html =
                '<div class="cropit-preview"></div>' +
//                        '<div class="image-size-label">' +
//                        '  Resize image' +
//                       ' </div>' +
                '<input type="range" class="cropit-image-zoom-input">'
                ;

        var htmlbutton = '<span class="export btn btn-primary albums-save-crop-button">Gebruik deze uitsnede</span>';
        $that.closest('.modal').find('.modal-footer').prepend(htmlbutton);

        $cropper.append(html);


        if (($that.closest('.modal-body').innerWidth() - 40) > cropper_x) {
            var cropper_width = cropper_x;
        } else {
            var cropper_width = $that.closest('.modal-body').innerWidth() - 40;
        }

        $that.closest('.modal-body').html($cropper);

        if ((parseInt(image['width']) < parseInt(cropper_x)) || (parseInt(image['height']) < parseInt(cropper_y))) {
            
            
            var warning = '<div class="alert alert-warning" role="alert">De geselecteerde afbeelding is te klein en zal worden uitgerekt.</div>';
            $('#image-cropper').prepend(warning);
        }

        $("#image-cropper").cropit({
            exportZoom: cropper_x / cropper_width,
            imageBackground: true,
            imageBackgroundBorderWidth: 20,
            width: cropper_width,
            height: cropper_width * cropper_y / cropper_x,
            smallImage: 'stretch',
            imageState: {
                //            src: '/temp/lorempixel.jpg',
                src: src,
            },
        });

        $('.export').click(function () {
            var request = loader.show();
            var offset = $("#image-cropper").cropit('offset');
            var left = offset['x'];
            var top = offset['y'];
            var zoom = $("#image-cropper").cropit('zoom');
            var imageData = $('#image-cropper').cropit('export');
            saveImage(imageData, image_id, src, $(this).closest('.modal'), left, top, zoom, request);
//                    window.open(imageData);
        });
        
        
        
        
    });

}

function uploadImageFromDrag(id) {
    var elements = document.body.getElementsByClassName('upload_images_cat');

    for (var i = 0; i < elements.length; i++) {
        rescope_me($(elements[i]), id);
    }
}

function uploadImageFromLibrary() {
    $('img.albums-upload-from-library').click(function () {
        var request = loader.show();

        var $el = $(this);
        var image_id = $el.attr('data-imageid');
        var cropper_x = $('#' + $el.closest('.modal').attr('data-element-id') + '-element').find('.albums-cropper-info').attr('data-x');
        var cropper_y = $('#' + $el.closest('.modal').attr('data-element-id') + '-element').find('.albums-cropper-info').attr('data-y');

        var postdata = {
            'image_id': image_id,
            'cropper_x': cropper_x,
            'cropper_y': cropper_y,
        };

        $.post('/albums/image/get-images-this-size', postdata, function (data, status) {
            loader.hide(request);

            if (data) {

                var orightml = $el.closest('.modal-body').html();

                $el.closest('.modal')
                        .off('hidden.bs.modal')
                        .on('hidden.bs.modal', function (e) {
                            $(this).find('.modal-body').html(orightml);
                            $(this).find('.albums-save-crop-button').remove();
                            uploadImageFromLibrary();
                        });

                var html = '<div class="row">';
                html += data;
                html += '</div>';

                $el.closest('.modal-body').html(html);

                $('img.upload-this-size').click(function () {
                    var $modal = $(this).closest('.modal');

                    var $ele = $(this);

                    if ($('#' + $modal.attr('data-element-id') + '-element').find('.albums-image-decorator-left').is('img')) {
                        $('#' + $modal.attr('data-element-id') + '-element').find('.albums-image-decorator-left')
                                .attr('src', $ele.attr('src') + '?' + Math.random())
                                .attr('id', 'image_' + $ele.attr('data-cropid'))
                                .attr('data-width', $ele.attr('data-width'))
                                .attr('data-height', $ele.attr('data-height'))
                                .attr('data-cropid', $ele.attr('data-cropid'))
                                .attr('data-imageid', $ele.attr('data-imageid'))
                                .attr('data-left', $ele.attr('data-left'))
                                .attr('data-top', $ele.attr('data-top'))
                                .attr('data-zoom', $ele.attr('data-zoom'))
                                ;

                    } else {
                        $('#' + $modal.attr('data-element-id') + '-element').find('.albums-image-decorator-left').after(
                                '<img class="albums-image-decorator-left" style="max-width:100%;max-height:150px;cursor:pointer;float:left;" '
                                + 'src="' + $ele.attr('src') + '" '
                                + 'id="image_' + $ele.attr('data-cropid') + '" '
                                + 'data-width="' + $ele.attr('data-width') + '" '
                                + 'data-height="' + $ele.attr('data-height') + '" '
                                + 'data-cropid="' + $ele.attr('data-cropid') + '" '
                                + 'data-imageid="' + $ele.attr('data-imageid') + '" '
                                + 'data-left="' + $ele.attr('data-left') + '" '
                                + 'data-top="' + $ele.attr('data-top') + '" '
                                + 'data-zoom="' + $ele.attr('data-zoom') + '" />'

                                + '<div style="cursor:pointer;float:left;" class="albums-delete-module-image"><i class="fa fa-remove fa-fw text-danger fa-2x" ></i></div>'
                                );
                        $('#' + $modal.attr('data-element-id') + '-element').find('.albums-image-decorator-left.no-image').remove();
                        resizeImage();
                        albumDeleteModuleImages();

                    }
                    $('#' + $modal.attr('data-element-id') + '-element').find('.sys-form-element-image-upload-display-hidden').val($ele.attr('data-cropid'));
                    $modal.modal('hide');

                });

                $('.album-use-new-crop').click(function () {

                    var src = $el.attr('src');
                    var $cropper = $('<div id="image-cropper" />');
                    var html =
                            '<div class="cropit-preview"></div>' +
                            '<input type="range" class="cropit-image-zoom-input">'
                            ;
                    $cropper.append(html);

                    var htmlbutton = '<span class="export btn btn-primary albums-save-crop-button">Gebruik deze uitsnede</span>';
                    $(this).closest('.modal').find('.modal-footer').prepend(htmlbutton);


                    if (($(this).closest('.modal-body').innerWidth() - 40) > cropper_x) {
                        var cropper_width = cropper_x;
                    } else {
                        var cropper_width = $(this).closest('.modal-body').innerWidth() - 40;
                    }


                    if ((parseInt($el.attr('data-width')) < parseInt(cropper_x)) || parseInt(($el.attr('data-height')) < parseInt(cropper_y))) {
                        var warning = '<div class="alert alert-warning" role="alert">De geselecteerde afbeelding is te klein en zal worden uitgerekt.</div>';
                        $cropper.prepend(warning);
                    }

                    $(this).closest('.modal-body').html($cropper);

                    $("#image-cropper").cropit({
                        exportZoom: cropper_x / cropper_width,
                        imageBackground: true,
                        imageBackgroundBorderWidth: 20,
                        width: cropper_width,
                        height: cropper_width * cropper_y / cropper_x,
                        smallImage: 'stretch',
                        imageState: {
                            src: src,
                        },
                    });

                    $('.export').click(function () {
                        var request = loader.show();
                        var offset = $("#image-cropper").cropit('offset');
                        var left = offset['x'];
                        var top = offset['y'];
                        var zoom = $("#image-cropper").cropit('zoom');
                        var imageData = $('#image-cropper').cropit('export');
                        saveImage(imageData, image_id, src, $(this).closest('.modal'), left, top, zoom, request);
                        //            window.open(imageData);
                    });

                });

            } else {

                var src = $el.attr('src');

                var $cropper = $('<div id="image-cropper" />');
                var html =
                        '<div class="cropit-preview"></div>' +
                        '<input type="range" class="cropit-image-zoom-input">'
                        ;
                $cropper.append(html);

                var htmlbutton = '<span class="export btn btn-primary albums-save-crop-button">Gebruik deze uitsnede</span>';
                $el.closest('.modal').find('.modal-footer').prepend(htmlbutton);

                var orightml = $el.closest('.modal-body').html();

                $el.closest('.modal')
                        .off('hidden.bs.modal')
                        .on('hidden.bs.modal', function (e) {
                            $(this).find('.modal-body').html(orightml);
                            $(this).find('.albums-save-crop-button').remove();

                            uploadImageFromLibrary();
                        });

                if (($el.closest('.modal-body').innerWidth() - 40) > cropper_x) {
                    var cropper_width = cropper_x;
                } else {
                    var cropper_width = $el.closest('.modal-body').innerWidth() - 40;
                }


                if ((parseInt($el.attr('data-width')) < parseInt(cropper_x)) || parseInt(($el.attr('data-height')) < parseInt(cropper_y))) {
                    var warning = '<div class="alert alert-warning" role="alert">De geselecteerde afbeelding is te klein en zal worden uitgerekt.</div>';
                    $cropper.prepend(warning);
                }

                $el.closest('.modal-body').html($cropper);

                $("#image-cropper").cropit({
                    exportZoom: cropper_x / cropper_width,
                    imageBackground: true,
                    imageBackgroundBorderWidth: 20,
                    width: cropper_width,
                    height: cropper_width * cropper_y / cropper_x,
                    smallImage: 'stretch',
                    imageState: {
                        //            src: '/temp/lorempixel.jpg',
                        src: src,
                    },
                });

                $('.export').click(function () {
                    var request = loader.show();
                    var offset = $("#image-cropper").cropit('offset');
                    var left = offset['x'];
                    var top = offset['y'];
                    var zoom = $("#image-cropper").cropit('zoom');
                    var imageData = $('#image-cropper').cropit('export');
                    saveImage(imageData, image_id, src, $(this).closest('.modal'), left, top, zoom, request);
                    //            window.open(imageData);
                });
            }
        });
    });
}

function saveImage(imageData, image_id, src, $modal, left, top, zoom, request, cropid) {
    var postdata = {
        'imageData': imageData,
        'image_id': image_id,
        'src': src,
        'left': left,
        'top': top,
        'zoom': zoom,
        'cropid': cropid,
    }

    $.post('/albums/categorie/upload-crop', postdata, function (data, status) {
        loader.hide(request);
        if ($('#' + $modal.attr('data-element-id') + '-element').find('.albums-image-decorator-left').is('img')) {
            $('#' + $modal.attr('data-element-id') + '-element').find('.albums-image-decorator-left')
                    .attr('src', data['image_location'] + '?' + Math.random())
                    .attr('id', 'image_' + data['id'])
                    .attr('data-width', data['width'])
                    .attr('data-height', data['height'])
                    .attr('data-cropid', data['id'])
                    .attr('data-imageid', data['image_id'])
                    .attr('data-left', data['left'])
                    .attr('data-top', data['top'])
                    .attr('data-zoom', data['zoom'])
                    ;

        } else {
            $('#' + $modal.attr('data-element-id') + '-element').find('.albums-image-decorator-left').after(
                    '<img class="albums-image-decorator-left" style="max-width:100%;max-height:150px;cursor:pointer;float:left;" '
                    + 'src="' + data['image_location'] + '" '
                    + 'id="image_' + data['id'] + '" '
                    + 'data-width="' + data['width'] + '" '
                    + 'data-height="' + data['height'] + '" '
                    + 'data-cropid="' + data['id'] + '" '
                    + 'data-imageid="' + data['image_id'] + '" '
                    + 'data-left="' + data['left'] + '" '
                    + 'data-top="' + data['top'] + '" '
                    + 'data-zoom="' + data['zoom'] + '" />'

                    + '<div style="cursor:pointer;float:left;" class="albums-delete-module-image"><i class="fa fa-remove fa-fw text-danger fa-2x" ></i></div>'
                    );
            $('#' + $modal.attr('data-element-id') + '-element').find('.albums-image-decorator-left.no-image').remove();
            resizeImage();
            albumDeleteModuleImages();

        }
        $('#' + $modal.attr('data-element-id') + '-element').find('.sys-form-element-image-upload-display-hidden').val(data['id']);
        $modal.modal('hide');

    }, 'json');

}

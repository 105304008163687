var friendlyUrl = function(title,prefix) {
	
	if(prefix == null) {
		prefix = '';
	}
	
	var url = title
		.toLowerCase() // change everything to lowercase
		.replace(/^\s+|\s+$/g, "") // trim leading and trailing spaces		
		.replace(/[_|\s]+/g, "-") // change all spaces and underscores to a hyphen
		.replace(/[_|\s]+/g, "-") // change all spaces and underscores to a hyphen
		.replace(/[^a-z0-9\/-]+/g, "") // remove all non-alphanumeric characters except the hyphen
		.replace(/[-]+/g, "-") // replace multiple instances of the hyphen with a single instance
		.replace(/^-+|-+$/g, "") // trim leading and trailing hyphens				
	;
	if(url.substr(0, prefix.length) == prefix) {
            return url;
        }
	return prefix + url;
};

$(document).ready(function () {
//    alert('asd');
    var uris = document.body.getElementsByClassName('news_translation_uri');
    var titles = document.body.getElementsByClassName('news_translation_title');
    
    
    var date = $('#configuration-date_published-element input').val();
    if(date) {
        var dateArr = date.split('-');
        var year = dateArr[2];
        var month = dateArr[1];
        
    }

    $('#configuration-date_published-element input').change(function() {
        for(i = 0; i < uris.length; i++) {
            var date = $(this).val();
            var dateArr = date.split('-');
            var year = dateArr[2];
            var month = dateArr[1];
            var title = $(uris[i]).closest('.tab-pane').find('input.news_translation_title').val();
            $(uris[i]).attr('placeholder', '/' + year + '/' + month + '/' + friendlyUrl(title));
        }
    });
    
    for(i = 0; i < uris.length; i++) {
        var title = $(uris[i]).closest('.tab-pane').find('input.news_translation_title').val();
        $(uris[i]).attr('placeholder', '/' + year + '/' + month + '/' + friendlyUrl(title));
    }
    
    for(i = 0; i < titles.length; i++) {
        $(titles[i]).keyup(function() {
            var date = $('#configuration-date_published-element input').val();
            var dateArr = date.split('-');
            var year = dateArr[2];
            var month = dateArr[1];
            var title = $(this).val();
            $(this).closest('.tab-pane').find('input.news_translation_uri').attr('placeholder', '/' + year + '/' + month + '/' + friendlyUrl(title))
        });
    }
    
}); 
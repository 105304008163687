function uploadAFile(id, url, callback, onStart, beforeUpload) {
    if(!beforeUpload) beforeUpload = function(data, start) { start(); };
    function uploadFile(e, fileObject, contents) {
        onStart();
        var xmlHttpRequest = new XMLHttpRequest();
        //Some AJAX-y stuff - callbacks, handlers etc.
        xmlHttpRequest.open("POST", url, true);
        var dashes = '--';
        var boundary = 'aperturephotoupload';
        var crlf = "\r\n";
        var filetype = 'application/octet-stream';
        //Post with the correct MIME type (If the OS can identify one)

        if (fileObject.type != '') {
            filetype = fileObject.type;
        }

        //Build a HTTP request to post the file
        var data = dashes + boundary + crlf +
                "Content-Disposition: form-data;" +
                "name=\"file[]\";" +
                "filename=\"" + unescape(encodeURIComponent(fileObject.name)) + "\"" + crlf +
                "Content-Type: " + filetype + crlf +
                crlf +
                contents + crlf +
                dashes + boundary + dashes;

        xmlHttpRequest.setRequestHeader("Content-Type", "multipart/form-data;boundary=" + boundary);

        xmlHttpRequest.onreadystatechange = function () {
            if (xmlHttpRequest.readyState === XMLHttpRequest.DONE && xmlHttpRequest.status === 200)
                callback(xmlHttpRequest.responseText);
        };
        //Send the binary data
        xmlHttpRequest.send(data);

    }

    function readFile(file) {
        reader = new FileReader();
        reader.onload = function (event) {
                uploadFile(event, file, event.target.result);
            
        }
        reader.readAsDataURL(file);
    }

    function readfiles(files) {
            beforeUpload(files, function() {
        for (var i = 0; i < files.length; i++) {
            readFile(files[i]);
        }
            });
    }
    var holder = document.getElementById(id);
    holder.ondragover = function () {
        this.className = 'hover upload_file';
        return false;
    };
    holder.ondragleave = function () {
        this.className = 'upload_file';
        return false;
    };
    holder.ondrop = function (e) {
        this.className = 'upload_file';
        e.preventDefault();
        readfiles(e.dataTransfer.files);
    }
}

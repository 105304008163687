


function renderModal(html, title, parent, url) {
    var $content = $(html);
    var $buttons = $content.find('.btn');
    
    var modal = $('<div class="modal fade" id="tempModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">'
            + '<div class="modal-dialog" role="document">'
            + '<div class="modal-content">'
            + '<div class="modal-header">'
            + '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
            + '<h4 class="modal-title" id="myModalLabel">'
            + title
            + '</h4>'
            + '</div>'
            + '<div class="modal-body">'
            + '</div>'
            + '<div class="modal-footer">'
            + '</div>'
            + '</div>'
            + '</div>'
            + '</div>')
            ;
//           buttons.hide();
//    modal.find('.modal-footer').append(buttons.clone());
    modal.find('.modal-body').append($content);
    $buttons.each(function() {
        var $clone = $(this).clone();
        var $that = $(this);
        modal.find('.modal-footer').append($clone);
        $clone.click(function() {
            $that.click();
        });
        $that.hide();
    })
    content.init($content, url, parent, modal);
    $('body').prepend(modal);
    modal.modal();
    return modal;
}

function createModal(parent, url, title, callback) {
    if(!callback) callback = function($modal) { };
    $('#openTempModal').remove();
    $('#tempModal').remove();

    $.get(url, function (data, status) {
        var $modal = renderModal(data.content, title, parent, url);
        callback($modal);
    }, 'json');
}



var loader = {
    counter: 0,
    open: [],
    show: function() {
        $('#full-page-loader').fadeTo(1000, 0.8);
        this.open.push({
            count: ++this.counter,
            closed: false
        });
        return this.counter;
    },
    hide: function(count) {
        var close = true;
        
        for(var i = 0; i < this.open.length; i++) {
            if(this.open[i].count == count) {
                this.open[i].closed = true;
            }
            else if(!this.open[i].closed) {
                close = false;
            }
        }
        
        if(close) {
            $('#full-page-loader').fadeOut(600);
        }
        
    }
}

//$( window ).on( "load", function() {
//    $('#full-page-loader').fadeOut(600);
//})

$(document).ready(function () {
    
    $('[data-toggle="offcanvas"]').click(function () {
        if ($(this).data('target') && $(this).data('target').length > 0) {
            $($(this).data('target')).toggleClass('active');
        } else {
            $('.row-offcanvas').toggleClass('active');
        }
    });
    
});


  
content.addFunction(function ($el, dialog, parent) {
    $el.find('#cancel').click(function () {
        $.get($(this).data('cancel'), function (data) {
            if (data.action == 'close') {
                if (parent) {
                    
                    dialog.modal.modal('hide')
                    ;
                } else {
                    history.go(-1);
                }
            }
        }, "json");

    });

});

tinymce.init({
  selector: 'textarea.tiny_mce',
  height: 400,
  autoresize_min_height: 400,
  menubar: false,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table contextmenu paste code',
    'autoresize'
  ],
  toolbar: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | code',
  audio_template_callback: function(data) {
      console.log(data);
   return '<audio controls>' + '\n<source src="' + data.source1 + '"' + (data.source1mime ? ' type="' + data.source1mime + '"' : '') + ' />\n' + '</audio>';
 },
  video_template_callback: function(data) {
      console.log(data);
   return '<audio controls>' + '\n<source src="' + data.source1 + '"' + (data.source1mime ? ' type="' + data.source1mime + '"' : '') + ' />\n' + '</audio>';
 },
  media_url_resolver: function (data, resolve/*, reject*/) {
      console.log(data);
    if (data.url.indexOf('youtu.be') !== -1) {
        var split = data.url.split('/');
        console.log(split);
        console.log(split[split.length - 1]);
        var youtube_key = split[split.length - 1];
      var embedHtml = '<div class="embed-responsive embed-responsive-16by9"><iframe src="https://www.youtube.com/embed/' + youtube_key +
      '" width="100%" height="auto" frameborder="0" allowfullscreen="allowfullscreen" ></iframe></div>';
      resolve({html: embedHtml});
  } else if (data.url.indexOf('vimeo.com') !== -1) {
        var split = data.url.split('/');
        console.log(split);
        console.log(split[split.length - 1]);
        var vimeo_key = split[split.length - 1];
      var embedHtml = '<div class="embed-responsive embed-responsive-16by9"><iframe src="https://player.vimeo.com/video/' + vimeo_key + '" width="100%" height="auto" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>';
      resolve({html: embedHtml});
    } else {
      resolve({html: ''});
    }
  }
});
